import { Component, OnInit } from '@angular/core';
import { AbstractPage } from 'src/app/pages/abstract.page';
import { environment } from 'src/environments/environment';
import { SearchBoxService } from 'src/app/services/search-box.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from "@angular/common";
import { ElementService } from 'src/app/services/element.service';
import { Elemento } from 'src/app/models/elemento.model';
import { ElementoFiltro } from 'src/app/models/elementoFiltro.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LangService } from "src/app/services/lang.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent extends AbstractPage implements OnInit {

  // IsEnglish = false;
  isEnglish = false;
  elemento: Elemento;
  idElemento: number;
  selectedImage: string = ''; 

  scale: number = 1;       
  startX: number = 0;       
  startY: number = 0;       
  isTouching: boolean = false;
  transformStyle: string = 'scale(1)'; 

  constructor(
    private searchBoxService: SearchBoxService,
    private platformLocation: PlatformLocation,
    private sanitizer: DomSanitizer,
    private elementoService: ElementService,
    private route: ActivatedRoute,
    private langService: LangService,
    private router: Router,
    private modalService: NgbModal
  ) {
    super();
    this.searchBoxService.showSearchBox();
  }

  public pageTitle(): string {
    return environment.title;
  }

  ngOnInit(): void {
    this.langService.getLangV2().subscribe((lang) => {
      this.isEnglish = lang.toLowerCase().trim() === "en";
    });

    this.route.queryParams.subscribe((params) => {
      this.idElemento = +this.router.url.substring(
        this.router.url.lastIndexOf('/') + 1,
        this.router.url.length
      );
      this.loadEvent(this.idElemento);
    });
  }

  private loadEvent(id: number) {
    var filtroDetalle: ElementoFiltro = new ElementoFiltro();
    filtroDetalle.id = id;
    this.elementoService.getAll(0, 0, filtroDetalle, '').subscribe(
      (data) => {
        this.elemento = (data as any)['elementos'][0];
        if (this.elemento && this.elemento.fechaInfoInicio != null) {
          this.elemento.fechaInfoInicio = new Date(this.elemento.fechaInfoInicio);
        }
      }
    );
  }

  openImageModal(content: any, imageUrl: string): void {
    this.selectedImage = imageUrl; 
    this.modalService.open(content, { centered: true, size: 'lg' });
  }


  onTouchStart(event: TouchEvent): void {
    this.isTouching = true;
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  onTouchMove(event: TouchEvent): void {
    if (!this.isTouching) return;

    const touchDistanceX = event.touches[0].clientX - this.startX;
    const touchDistanceY = event.touches[0].clientY - this.startY;

    const zoomFactor = Math.sqrt(touchDistanceX * touchDistanceX + touchDistanceY * touchDistanceY) / 100;
    this.scale = Math.max(1, zoomFactor); 

    this.transformStyle = `scale(${this.scale})`;
  }

  onTouchEnd(event: TouchEvent): void {
    this.isTouching = false;
  }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
