<div class="breadcrumb">
  <!-- appCats -->
  <div class="container">
    <a routerLink="/" i18n="Home">Home</a>
    <span> / </span> <span><a routerLink="/blog">Blog</a></span>
    <span> / </span><span><a routerLink="/blog/events">{{ isEnglish ? 'Events' : 'Eventos' }}</a></span>
    <span> / </span> <span>{{ isEnglish ? elemento.copete : elemento.nombre }}</span>
  </div>
</div>

<!-- Eventos 
    nombre = Titulo español
    copete = Titulo ingles
    descripcionHTML = Descripcion español
    descripcion = Descripcion ingles
    fuente = Ubicacion
    fechaElementoInicio = Visible desde
    fechaElementoFin = Visible hasta 
    estadoElemento = Estado
    -->

    <div class="container" *ngIf="elemento">
      <!-- Carrusel para múltiples imágenes -->
      <ngb-carousel *ngIf="(elemento.detailImage || elemento.detailImage2 || elemento.detailImage3) &&
                            (elemento.detailImage2 || elemento.detailImage3)"
                    interval="9000" pauseOnHover="true" [showNavigationArrows]="true" class="carousel">
        <ng-template ngbSlide *ngIf="elemento.detailImage">
          <div class="carousel-item" (click)="openImageModal(content, elemento.detailImage)">
            <img class="carousel-image" [src]="elemento.detailImage" [alt]="isEnglish ? elemento.copete : elemento.nombre" />
          </div>
        </ng-template>
        <ng-template ngbSlide *ngIf="elemento.detailImage2">
          <div class="carousel-item" (click)="openImageModal(content, elemento.detailImage2)">
            <img class="carousel-image" [src]="elemento.detailImage2" [alt]="isEnglish ? elemento.copete : elemento.nombre" />
          </div>
        </ng-template>
        <ng-template ngbSlide *ngIf="elemento.detailImage3">
          <div class="carousel-item" (click)="openImageModal(content, elemento.detailImage3)">
            <img class="carousel-image" [src]="elemento.detailImage3" [alt]="isEnglish ? elemento.copete : elemento.nombre" />
          </div>
        </ng-template>
      </ngb-carousel>
    
      <!-- Imagen única, sin carrusel -->
      <div *ngIf="elemento.detailImage && !elemento.detailImage2 && !elemento.detailImage3"
           class="single-image-container"
           (click)="openImageModal(content, elemento.detailImage)">
        <img class="single-image" [src]="elemento.detailImage" [alt]="isEnglish ? elemento.copete : elemento.nombre" />
      </div>
    </div>
    
    <!-- Modal -->
    <ng-template #content let-modal>
      <div class="modal-overlay" (click)="modal.dismiss('Backdrop click')">
        <button
          type="button"
          class="close-button"
          aria-label="Close"
          (click)="modal.dismiss('Cross click'); $event.stopPropagation()"
        >
          &times;
        </button>
        <div class="image-container" (click)="$event.stopPropagation()">
          <img [src]="selectedImage" class="modal-image" alt="Imagen en pantalla completa">
        </div>
      </div>
    </ng-template>
    
  <div class="event-info container">
    <div class="event-header">
      <h1 class="event-title">{{ isEnglish ? elemento.copete : elemento.nombre }}</h1>
      <div class="aditional-info">
        <p class="event-date">
          {{ elemento.fechaInfoInicio | date: 'dd MMMM yyyy' }}
          <span *ngIf="elemento.fechaInfoFin"> - {{ elemento.fechaInfoFin | date: 'dd MMMM yyyy' }}</span>
        </p>
        
        <p class="event-location">{{ elemento.fuente }}</p>
      </div>
    </div>

    <div class="event-description">
      <p [innerHtml]="sanitizeHtml(isEnglish ? elemento.descripcion : elemento.descripcionHtml)"></p>
    </div>
  </div>