import { Component, OnInit } from '@angular/core';
import { ElementService } from 'src/app/services/element.service';
import { Elemento } from 'src/app/models/elemento.model';
import { ElementoFiltro } from 'src/app/models/elementoFiltro.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PlatformLocation } from "@angular/common";
import { SearchBoxService } from 'src/app/services/search-box.service';
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: 'blog-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  elementos: Elemento[] = [];
 // IsEnglish = false;
 isEnglish = false;
  isLoading: boolean = true; // Estado de carga

  constructor(
    private elementService: ElementService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private searchBoxService: SearchBoxService,
    private platformLocation: PlatformLocation,
    private langService : LangService,
  ) {}

  ngOnInit(): void {
    this.initializeData(); 
    this.route.queryParams.subscribe(params => {
      const modulo = params['modulo'];
      const categoriaId = params['categoria'];

      if (modulo === 'Blog') {
        this.loadBlogElements(categoriaId);
      } else {
        this.isLoading = false; 
      }
    });
    this.langService.getLangV2().subscribe((lang) => {
      this.isEnglish = lang.toLowerCase().trim() === "en";
    });
     console.log(this.isEnglish)
  }

  private initializeData() {
  
  }

  private loadBlogElements(categoriaId: string): void {
    const filtroBlog: ElementoFiltro = new ElementoFiltro();
    filtroBlog.modulo = 'Blog';
    filtroBlog.categorias = [categoriaId];
 
    this.elementService.getAll(100, 0, filtroBlog, '')
      .subscribe(
        data => {
          // Orden por fecha de inicio del evento
          this.elementos = data.elementos.sort((a, b) => {
            return new Date(b.fechaInfoInicio || 0).getTime() - new Date(a.fechaInfoInicio || 0).getTime();
          });
        },
        error => {
          console.error('Error al traer los elementos:', error);
        },
        () => {
          this.isLoading = false; // Finaliza la carga
        }
      );
  }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
